body{
  font-family: $textFont;
  color: $dark;
}

p{
  color: $dark;
  font-size: 16px;
  line-height: 26px;
}

small{
  font-size: 12px;
  line-height: 16px;
}

._heading{
  font-family: $headingFont;
  
}

._heading-1{
  font-family: $headingFont;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  @media(min-width: $tablet){
    font-size: 27.5px;
    line-height: 35px;
  }
  @media(min-width: $laptop){
    font-size: 30px;
    line-height: 40px;
  }
}

._heading-2{
  font-family: $headingFont;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}

._button{
  font-family: $textFont;
  background-color: $orange;
  color: #fff;
  min-height: 50px;
  display: inline-block;
  border: none;
  font-weight: 600;
  padding: 12px 20px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  min-width: 168px;
}

._input-group{
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  text-align: left;
  ._input-element{
    width: 100%;
  }
}

._input-row{
  @media(min-width: $tablet){
    @include flexbox;
    justify-content: space-between;
    width: 100%;
    ._input-group{
      width: calc(50% - 7.5px);
    }
  }
  label{
    color: $grey;
    font-weight: 600;
    font-size: 14px;
  }
}

._input-element{
  min-height: 40px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $light;
  color: $dark;
  font-size: 16px;
  line-height: 26px;
  font-family: $textFont;
  background: transparent;
  padding-left: 0;
  &::placeholder{
    color: $dark;
    font-size: 16px;
    line-height: 26px;
    font-family: $textFont;
    opacity: 1;
  }
  
}

select._input-element{
  padding-top: 5px;
  appearance: button;
  -moz-appearance: button;
  -webkit-appearance: button;
}

._errors{
  ._error{
    display: block;
    color: red;
    text-align: left;
    padding: 5px 0;
    font-weight: 600;
    font-size: 14px;
  }
}

._contact-details{
  ._item{
    display: flex;
    align-items: center;
    margin: 30px 0 0 0;
    text-align: left;
    justify-content: flex-start;
    img{
      width: 40px;
      margin-right:20px;
    }
  }
  @media(min-width: $tablet){
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 20px;
    ._item{
      width: auto;
      span{
        white-space: nowrap;
      }
    }
  }
}

._overlay-1{
  position: relative;
  z-index: 0;
  img{
    position: absolute;
    top: 150px;
    right: 0;
    max-width: 340px;
    width: 30%;
  }
}

._overlay-2{
  position: relative;
  z-index: 0;
  img{
    position: absolute;
    top: -250px;
    left: 0;
    max-width: 340px;
    width: 30%;
  }
}