._section{
  text-align: center;
  @include paddingTopBottom;
  
  ._icon{
    width: 100px;
  }
  ._heading-1{
    margin-top: 0;
    margin-bottom: 30px;
  }
  ._heading-2{
    margin-top: 50px;
  }
  ._panel{
    max-width: 720px;
    margin: 0 auto;
  }
  p{
    margin: 30px 0;
  }
  ._grid-3{
    justify-content: center;
    p{
      margin: 15px 0;
    }
    @media(min-width: $tablet){
      p{
        margin: 15px 0 0 0;
      }
    }
  }
  *, input{
    position: relative;
    z-index: 1;
  }
}

._section-more-services{
  background-position: center;
  background-size: cover;
  @include paddingTopBottom;
  ._grid{
    position: relative;
    height: 100%;
  }
  ._heading-1{
    margin-top: 0;
    margin-bottom: 0;
  }
  p{
    margin-bottom: 0;
  }
  ._panel{
    text-align: center;
    img{
      width: 40px;
      margin-right: 10px;
    }
    ._flex{
      justify-content: center;
    }
    @media(min-width: $tablet){
      text-align: left;
      position: absolute;
      left: calc(50% + 100px);
      top: 50%;
      transform: translateY(-50%);
      ._flex{
        justify-content: flex-start;
      }
    }
  }
  @media(min-width: $tablet){
    height: 400px;
  }
}

._section-contact-us{
  @include paddingTopBottom;
  text-align: center;
  ._panel{
    max-width: 800px;
    margin: 0 auto;
  }
  ._heading-1{
    margin: 0 0 30px 0;
  }
  p{
    margin: 30px 0;
  }
  form{
    max-width: 600px;
    margin: 0 auto;
  }
}

._our-fees{
  align-items: stretch;
  ._column{
    padding: 50px 20px;
    background-color: $veryLight;
    position: relative;
    ._icon-cut-off{
      position: absolute;
      right: 0;
      top: 0;
      max-width: 30%;
      max-height: 100%;
    }
    @media(min-width: $laptop){
      border-bottom: 10px solid $orange;
    }
  }
}

._our-services{
  ._column{
    background-color: #fff;
    padding: 10px 10px 30px 10px;
  }
}