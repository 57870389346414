header{
  background-color: $dark;
  height: $headerSpace;
  position: relative;
  ._grid{
    height: 100%;
    ._column{
      height: 100%;
      @include flexbox;
      justify-content: center;
      align-items: center;
      @media(min-width: $tablet){
        justify-content: space-between;
      }
      #contact-button{
        display: none;
        @media(min-width: $tablet){
          display: block;
        }
      }
      ._logo{
        padding: 50px 15px 0 15px;
        background-color: $dark;
        height: 100%;
        img{
          width: 120px;
        }
      }
    }
  }
  @media(min-width: $tablet){
    background-color: transparent;
    margin-bottom: -$headerSpace;
  }
}

footer{
  background-color: $dark;
  color: #fff;
  padding: 50px 0;
  p{
    color: #fff;
  }
}