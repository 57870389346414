@mixin paddingTopBottom{
  padding-top: 50px;
  padding-bottom: 50px;
  @media(min-width: $tablet){
    padding-top: 75px;
    padding-bottom: 75px;
  }
  @media(min-width: $laptop){
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@mixin paddingBottom{
  padding-bottom: 50px;
  @media(min-width: $tablet){
    padding-bottom: 75px;
  }
  @media(min-width: $laptop){
    padding-bottom: 100px;
  }
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}