//fonts
$headingFont: 'Rubik', Arial, Helvetica, sans-serif;
$textFont: Arial, Helvetica, sans-serif;

//media queries
$tablet: 768px;
$laptop: 1124px;
$desktop: 1300px;

// colors
$orange: #F15F12;
$dark: #061D30;
$light: #D8D8D8;
$grey: #83888d;
$veryLight: #F8F8F8;

// spacing
$headerSpace: 145px;