._orange{
  color: $orange;
}

._flex{
  @include flexbox;
  align-items: center;
}

._light_background{
  background-color: $veryLight;
}

._padding-bottom-100{
  @include paddingBottom;
}

._margin-bottom-0{
  margin-bottom: 0!important;
}

._widescreen{
  display: none;
  @media(min-width: $laptop){
    display: block;
  }
}

._tablet{
  display: none;
  @media(min-width: $tablet){
    display: block;
  }
}

._mobile{
  @media(min-width: $laptop){
    display: none;
  }
}